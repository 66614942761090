import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const basicCalculator: CalculatorInfo = {
  id: 'basic-calculator',
  title: 'Online Calculator | Free Basic Calculator for Math',
  description: 'Free online basic calculator with essential arithmetic operations. Perform addition, subtraction, multiplication, division, and percentages with high precision. Perfect for quick calculations, budgeting, and everyday math.',
  icon: Calculator,
  category: 'Academic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Basic Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Math",
    "operatingSystem": "Any",
    "description": "Free online basic calculator with essential arithmetic operations. Perfect for quick calculations and everyday math needs.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/basic-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1250",
      "reviewCount": "820",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Lightning Fast Calculations",
      "No Installation Required",
      "Mobile Friendly Design",
      "Privacy First - No Data Collection",
      "High Precision Results",
      "Free Forever",
      "Order of Operations (PEMDAS)",
      "Percentage Calculations",
      "Chain Operations",
      "Negative Numbers Support"
    ]
  },
  formulas: [
    {
      expression: 'a + b',
      explanation: 'Addition combines two numbers into their sum',
      variables: [
        { name: 'a', description: 'First number' },
        { name: 'b', description: 'Second number' }
      ]
    },
    {
      expression: 'a × b',
      explanation: 'Multiplication produces the product of two numbers',
      variables: [
        { name: 'a', description: 'First number' },
        { name: 'b', description: 'Second number' }
      ]
    },
    {
      expression: '(a × b) ÷ 100',
      explanation: 'Percentage calculation',
      variables: [
        { name: 'a', description: 'Number to calculate percentage of' },
        { name: 'b', description: 'Percentage value' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What operations can I perform?',
      answer: 'You can perform addition (+), subtraction (-), multiplication (×), division (÷), and calculate percentages. You can also chain multiple operations together.'
    },
    {
      question: 'Does it follow order of operations?',
      answer: 'Yes, the calculator follows PEMDAS (Parentheses, Exponents, Multiplication/Division, Addition/Subtraction) order of operations.'
    },
    {
      question: 'Can I use negative numbers?',
      answer: 'Yes, you can use negative numbers in any calculation by using the minus (-) sign.'
    },
    {
      question: 'Is this calculator free to use?',
      answer: 'Yes, our basic calculator is completely free to use with no ads or hidden charges.'
    },
    {
      question: 'Does it work on mobile devices?',
      answer: 'Yes, the calculator is fully responsive and works perfectly on smartphones and tablets.'
    }
  ],
  references: [
    'Mathematical Order of Operations (PEMDAS)',
    'Basic Arithmetic Operations',
    'Percentage Calculations',
    'Digital Calculator Standards'
  ],
  keywords: [
    'basic calculator',
    'online calculator',
    'free calculator',
    'simple calculator',
    'arithmetic calculator',
    'math calculator',
    'percentage calculator',
    'PEMDAS calculator',
    'mobile calculator',
    'web calculator'
  ],
  features: [
    'Basic arithmetic operations',
    'Percentage calculations',
    'Order of operations (PEMDAS)',
    'Chain calculations',
    'Negative numbers support',
    'Mobile-friendly interface',
    'High-precision results',
    'No installation required',
    'Free to use',
    'Privacy focused'
  ]
};
